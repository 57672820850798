import david from './david.png'
import paul from './paul.png'
import Contact from "./contact"

function AboutBody() {
    return (
        <div className="container mx-auto max-w-6xl mb-20">
            <div className="grid md:grid-cols-3 gap-8 py-10 px-6 md:px-0">
                <div className="col-span-2">
                    <h2 className="text-4xl md:text-6xl App--deskLh">About Us</h2>
                    <p className="mt-6">Linton Capital builds on its long sector experience to bring a creative approach to the structuring and sourcing of capital.  In addition to deploying its own investment capital as principal, Linton has both managed and advised several funds and has established relationships with other funders.  This creative approach also extends to planning for restructuring and acquisitions to unlock both the best plan and also the capital on which to execute.</p>
                </div>
                <Contact />
            </div>
            <h3 className="text-4xl md:text-6xl ml-6 md:ml-0">Partners</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-8 py-10 px-6 md:px-0">
                <div className="col-span-2 md:col-span-1 md:mr-8 order-2 md:order-1">
                    <img src={david} alt="Partner David" />
                </div>
                <div className="col-span-2 order-1 md:order-2">
                    <p className="md:text-1xl mb-3 text-gold">MANAGING PARTNER </p>
                    <h2 className="text-2xl md:text-4xl">David Sefton</h2>
                    <p className="mt-6">David Sefton is an experienced private and public equity investor and board director.  David originally trained as a barrister before working in international cross border M&A and restructuring at Cleary, Gottlieb, Steen & Hamilton, based in New York and London.  He was then appointed Chief Legal Counsel at Lukoil Financial Services before founding Linton Capital in 2005.  David has raised capital as principal and managed both private and listed funds, as well as leading IPOs and private funding rounds of individual companies across two key sectors: media & technology and energy.  David has been Chairman of both public and private companies in the UK, the US and Canada and has also served as non-executive director on several boards.  In addition to considerable experience with start-ups and growth companies, David has led several successful restructurings of businesses in financial difficulty.  He is particularly known for conceiving innovative structures and solutions, and following through on execution.  David graduated from the University of Oxford with undergraduate and postgraduate degrees.  David is based in London and Oxfordshire.</p>
                </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-8 py-10 px-6 md:px-0">
                <div className="col-span-2 md:col-span-1 md:mr-8 order-2 md:order-1">
                    <img src={paul} alt="Investment director Paul" />
                </div>
                <div className="col-span-2 order-1 md:order-2">
                    <p className="md:text-1xl mb-3 text-gold">INVESTMENT DIRECTOR</p>
                    <h2 className="text-2xl md:text-4xl">Paul O'Donohoe </h2>
                    <p className="mt-6">Paul is a former professional rugby player with achievements including a European Heineken Cup winning medal with Leinster rugby. After retiring early due to injury in 2014 he moved into the media and entertainment industry in London, working across a range of international publishers, broadcasters, online businesses and rights holders where he has a deep network and knowledge. Paul has experience of raising and deploying debt and equity for companies in these sectors, often in connection with acquisitions.</p>
                </div>
            </div>
        </div>
    );
  }
  
  export default AboutBody;

  
  