import Navbar from "./navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./About";
import Home from "./Home";
import Footer from "./footer";

function App() {
  return (
    <div className="font-serif text-luna">
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />}>HOME</Route>
          <Route path="/about/" element={<About />}>ABOUT</Route>
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
