function Footer() {
    return (
        <div className="container mx-auto max-w-6xl border-t-2">
            <div className="grid md:grid-cols-2 gap-8 py-10 px-6 md:px-0 mt-5">
                <div>
                    <p>10 Richmond Mews, London W1D 3DD</p>
                    <button onClick={() => window.location = 'mailto:info@linton-capital.co.uk'}>info@linton-capital.co.uk</button>
                </div>
                <div>
                    <p>Linton Capital LLP was established in the UK with LLP number OC308234 is authorised and regulated by the UK Financial Conduct Authority with registered number 429992</p>
                </div>
            </div>
        </div>
    );
  }
  
  export default Footer;

  
  